.container {
  @apply grid grid-cols-11 h-[113px];
}

@media (min-width: 2048px) {
  .container {
    @apply h-[calc(113px_*_(1536_/_768))];
  }
}

@media (min-width: 2736px) {
  .container {
    @apply h-[calc(113px_*_(1824_/_768))];
  }
}
