.back-btn {
  @apply flex justify-center items-center h-[66px] w-[73px] bg-midnight-400 rounded-br-[20px];
}

@media screen and (min-width: 2048px) {
  .back-btn {
    @apply h-[calc(66px_*_(1536_/_768))] w-[calc(73px_*_(2048_/_1366))] rounded-br-[calc(20px_*_(1.75))];
  }
}

.back-btn-responsive {
  @apply w-[calc(26px_*_(2048_/_1366))];
}

@media screen and (min-width: 2736px) {
  .back-btn {
    @apply h-[calc(66px_*_(1824_/_768))] w-[calc(73px_*_(2736_/_1366))] rounded-br-[calc(20px_*_(2.19))];
  }
}

.back-btn-responsive {
  @apply w-[calc(26px_*_(2736_/_1366))];
}
