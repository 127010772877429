.button-submit-title {
  @apply h-[74px] bg-midnight-300 rounded-md mt-[26px] mx-[42px] text-base-700-normal text-white;
}

// Responsive
@media screen and (min-width: 2048px) {
  .button-submit-title {
    @apply h-[calc(74px_*_(1.75))] 
    rounded-[calc(0.375rem_*_(1.75))] 
    mt-[calc(26px_*_(1.75))] mx-[calc(42px_*_(1.75))]
    text-[calc(16px_*_(1.75))];
  }
}

@media screen and (min-width: 2736px) {
  .button-submit-title {
    @apply h-[calc(74px_*_(2.19))] 
    rounded-[calc(0.375rem_*_(2.19))] 
    mt-[calc(26px_*_(2.19))] mx-[calc(42px_*_(2.19))]
    text-[calc(16px_*_(2.19))];
  }
}
