.page-container {
  @apply h-screen flex flex-col justify-center items-center bg-gradient-to-t from-common-10 to-common-20;
}

.page-logo-icon {
  @apply mb-[54px];
}

.page-content > *,
.form-password > *,
.form-password-item {
  @apply w-full;
}

.form-password {
  @apply flex flex-col gap-1;
}

.page-content-title {
  @apply text-white text-24-700-normal mb-[19px];
}

.form-password-button-wrap {
  @apply text-center mt-[56px];
}

// Responsive
@media screen and (min-width: 2048px) {
  .page-logo-icon {
    @apply mb-[calc(54px_*_(1536_/_768))];
  }

  .form-password {
    @apply gap-[calc(4px_*_(1.75))];
  }

  .page-content-title {
    @apply text-[calc(24px_*_(1.75))] mb-[calc(19px_*_(1536_/_768))];
  }

  .form-password-button-wrap {
    @apply mt-[calc(56px_*_(1536_/_768))];
  }

  .logo-icon {
    @apply w-[calc(90px_*_(2048_/_1366))];
  }
}

@media screen and (min-width: 2736px) {
  .page-logo-icon {
    @apply mb-[calc(54px_*_(1824_/_768))];
  }

  .form-password {
    @apply gap-[calc(4px_*_(2.19))];
  }

  .page-content-title {
    @apply text-[calc(24px_*_(2.19))] mb-[calc(19px_*_(1824_/_768))];
  }

  .form-password-button-wrap {
    @apply mt-[calc(56px_*_(1824_/_768))];
  }

  .logo-icon {
    @apply w-[calc(90px_*_(2736_/_1366))];
  }
}
