.nav-item-wrap {
  @apply flex flex-col justify-between items-center relative pb-5 after:content-[''] after:absolute after:top-0 after:bottom-0 after:w-[1px] after:left-0 after:bg-transparent;
}

.nav-item-wrap::after {
  box-shadow: 0px 1px 1.625px rgba(26, 19, 70, 0.31);
}

.nav-icon {
  @apply h-full w-full flex justify-center items-center;
}

.nav-active {
  @apply bg-jade-500 text-white;
}

.nav-label {
  @apply text-midnight-500 text-sm-700-normal;
}

.nav-active .nav-label {
  @apply text-white;
}

@media (min-width: 2048px) {
  .nav-item-wrap {
    @apply pb-[calc(1.25rem_*_(1.75))] after:w-[calc(1px_*_(1.75))];
  }

  .nav-label {
    @apply text-[calc(15px_*_(1.75))];
  }
}

@media (min-width: 2736px) {
  .nav-item-wrap {
    @apply pb-[calc(1.25rem_*_(2.19))] after:w-[calc(1px_*_(2.2))];
  }

  .nav-label {
    @apply text-[calc(15px_*_(2.19))];
  }
}
