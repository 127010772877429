.employee-id-container {
    @apply w-screen h-screen min-w-[1366px] flex items-center justify-center bg-common-50;
  }
  
  .employee-id-banner {
    @apply w-[655px] h-full bg-gradient-to-b from-common-10 to-common-20 relative flex flex-col items-center justify-between;
  }
  
  .employee-id-banner-header,
  .employee-id-banner-content,
  .employee-id-banner-footer {
    @apply w-full;
  }
  
  .employee-id-banner-header {
    @apply pt-[19px] pl-[22px];
  }
  
  .employee-id-banner-header-logo {
    @apply py-4 px-3.5 w-14 h-14 rounded-full bg-common-40;
  }
  
  .employee-id-banner-header-logo img {
    @apply w-full h-full;
  }
  
  .employee-id-banner-content {
    @apply flex-1 pt-20 flex flex-col items-center;
  }
  
  .employee-id-banner-greeting-wrapper {
    @apply mb-9;
  }
  
  .employee-id-banner-date-wrapper {
    @apply mb-[33px];
  }
  
  .employee-id-banner-footer {
    @apply flex items-center;
  }
  
  .employee-id-banner-footer-title {
    @apply bg-common-40 py-2.5 pl-[30px] pr-[40px] w-[149px] rounded-tr-2xl text-white text-xl-700-normal truncate;
  }
  
  .employee-id-banner-footer-title-txt {
    @apply w-full text-white text-xl-700-normal truncate;
  }
  
  .employee-id-content {
    @apply flex-1 h-full px-[184px] pt-[76px] pb-[20px] overflow-hidden;
  }
  
  .employee-id-content-station-code {
    @apply mb-5;
  }
  
  .employee-id-content-keyboard {
    @apply h-full grid grid-cols-3 gap-5 mb-5 px-[9px];
  }
  
  .employee-id-content-actions {
    @apply grid grid-cols-2 gap-5 px-[9px];
  }
  
  .employee-id-content-form {
    @apply h-auto mb-[77px];
  }
  
  .employee-id-content-footer {
    @apply text-base-700-normal text-common-60 text-center;
  }
  