.footer-information-container {
  @apply flex flex-col;
}

.footer-information-title {
  @apply text-xl-700-normal text-midnight-500;
}

.footer-information-sub-title {
  @apply text-xl-700-normal text-jade-500;
}

// Responsive
@media screen and (min-width: 2048px) {
  .footer-information-title {
    @apply text-[calc(18px_*_(1.75))];
  }

  .footer-information-sub-title {
    @apply text-[calc(18px_*_(1.75))];
  }
}

@media screen and (min-width: 2736px) {
  .footer-information-title {
    @apply text-[calc(18px_*_(2.19))];
  }

  .footer-information-sub-title {
    @apply text-[calc(18px_*_(2.19))];
  }
}
