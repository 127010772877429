.info-row-wrap {
  @apply w-full flex h-[66px];
}

.back-btn {
  @apply flex justify-center items-center h-full w-[73px] bg-midnight-400;
}

.info-center-wrap {
  @apply flex-1 flex justify-evenly items-center bg-midnight-500 min-w-[232px];
}

.info-order-opacity {
  @apply opacity-55;
}

.info-time {
  @apply text-4xl;
}

.info-order-wrap {
  @apply flex flex-col text-base-700-normal;
}

.info-customer-status {
  @apply flex flex-col text-base-700-normal justify-center items-center bg-midnight-400 px-4;
}

.info-customer-status-pending {
  @apply text-sm-400-normal opacity-50 h-[24px];
}

// Responsive
@media screen and (min-width: 2048px) {
  .info-row-wrap {
    @apply h-[calc(66px_*_(1536_/_768))];
  }

  .back-btn {
    @apply w-[calc(73px_*_(2048_/_1366))];
  }

  .back-btn img {
    @apply w-[calc(26px_*_(2048_/_1366))] h-[calc(26px_*_(1536_/_768))];
  }

  .btn-back-icon {
    @apply w-[calc(26px*_(2048_/_1366))];
  }

  .info-center-wrap {
    @apply min-w-[calc(232px*_(2048_/_1366))];
  }

  .info-time {
    @apply text-[calc(39px*_1.75)];
  }

  .info-order-wrap {
    @apply text-[calc(16px*_1.75)];
  }

  .info-customer-status {
    @apply px-[calc(9px_*_(2048_/_1366))] text-[calc(16px_*_1.75)];
  }

  .info-customer-status-pending {
    @apply text-[calc(15px_*_1.75)] h-[calc(24px_*_(1536_/_768))];
  }
}

@media screen and (min-width: 2736px) {
  .info-row-wrap {
    @apply h-[calc(66px_*_(1824_/_768))];
  }

  .back-btn {
    @apply w-[calc(73px_*_(2736_/_1366))];
  }

  .back-btn img {
    @apply w-[calc(26px_*_(2736_/_1366))] h-[calc(26px_*_(1824_/_768))];
  }

  .btn-back-icon {
    @apply w-[calc(26px*_(2736_/_1366))];
  }

  .info-center-wrap {
    @apply min-w-[calc(232px*_(2736_/_1366))];
  }

  .info-time {
    @apply text-[calc(39px*_2.19)];
  }

  .info-order-wrap {
    @apply text-[calc(16px*_2.19)];
  }

  .info-customer-status {
    @apply px-[calc(12px_*_(2736_/_1366))] text-[calc(16px_*_2.19)];
  }

  .info-customer-status-pending {
    @apply text-[calc(15px_*_2.19)] h-[calc(24px_*_(1824_/_768))];
  }
}
