.sidebar-wrap {
  @apply w-full h-full flex flex-col text-white bg-midnight-600;
}

.main-content-wrap {
  @apply flex-1 mt-[3px] h-full max-h-full overflow-y-auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.main-content-wrap::-webkit-scrollbar {
  display: none;
}
