.button-primary {
  @apply bg-jade-500 rounded-[10px] text-20-700-normal text-center text-white;
}

.button-submit-landing {
  @apply py-[5px] px-[43px];
}

.button-login {
  @apply py-[21px] px-[50px];
}

// Responsive
@media screen and (min-width: 2048px) {
  .button-primary {
    @apply rounded-[calc(10px_*_(1.75))] text-[calc(20px_*_(1.75))];
  }

  .button-submit-landing {
    @apply py-[calc(5px_*_(1536_/_768))] px-[calc(43px_*_(2048_/_1366))];
  }

  .button-login {
    @apply py-[calc(21px_*_(1536_/_768))] px-[calc(50px_*_(2048_/_1366))];
  }
}

@media screen and (min-width: 2736px) {
  .button-primary {
    @apply rounded-[calc(10px_*_(2.19))] text-[calc(20px_*_(2.19))];
  }

  .button-submit-landing {
    @apply py-[calc(5px_*_(1824_/_768))] px-[calc(43px_*_(2736_/_1366))];
  }

  .button-login {
    @apply py-[calc(21px_*_(1824_/_768))] px-[calc(50px_*_(2736_/_1366))];
  }
}
