.main-layout-container {
  @apply w-screen h-screen flex flex-col min-w-[1366px];
}

.main-content-wrap {
  @apply flex flex-1;
}

.sidebar-wrap {
  @apply basis-[409px] h-full max-h-[calc(100vh-89px)];
}

.right-content {
  @apply flex flex-col flex-1;
}

.content-wrap {
  @apply flex-1 bg-midnight-500;
}

.footer-wrap {
  @apply w-full h-[90px];
}

// Responsive
@media screen and (min-width: 2048px) {
  .footer-wrap {
    @apply h-[calc(90px_*_(1536_/_768))];
  }

  .sidebar-wrap {
    @apply basis-[calc(409px_*_(2048_/_1366))] max-h-[calc(100vh_-_(89px_*_(1536_/_768)))];
  }
}

@media screen and (min-width: 2736px) {
  .footer-wrap {
    @apply h-[calc(90px_*_(1824_/_768))];
  }

  .sidebar-wrap {
    @apply basis-[calc(409px_*_(2736_/_1366))] max-h-[calc(100vh_-_(89px_*_(1824/768)))];
  }
}
