.order-type-container {
  @apply bg-jade-500 py-3 pl-5 w-full cursor-pointer;
}

.order-type-selected-wrapper {
  @apply flex items-center justify-between;
}

.order-type-selected {
  @apply flex-1 truncate;
}

.order-type-arrow {
  @apply w-[117px] flex items-center justify-center;
}

.order-type-selected-label {
  @apply text-base-700-normal;
}
