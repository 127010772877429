.input-code-container {
  @apply flex flex-col;
}

.input-code-title {
  @apply text-24-700-normal text-center text-midnight-500 mb-5;
}

.input-general {
  @apply h-[70px] text-center border-[3px];
}

.input-code {
  @apply border-midnight-500 rounded-[10px] text-[30px] text-midnight-500 bg-common-50;
}

.input-code-order-lookup {
  @apply border-common-50 rounded-[16px] text-white text-40-700-normal bg-midnight-500;
}

@media screen and (min-width: 2048px) {
  .input-code-title {
    @apply text-[calc(24px_*_(1.75))] mb-[calc(1.25rem_*_(1536_/_768))];
  }

  .input-general {
    @apply h-[calc(70px_*_(1536_/_768))] border-[calc(3px_*_(1.75))];
  }

  .input-code {
    @apply rounded-[calc(10px_*_(1.75))] text-[calc(30px_*_(1.75))];
  }

  .input-code-order-lookup {
    @apply rounded-[calc(16px_*_(1.75))] text-[calc(40px_*_(1.75))];
  }
}

@media screen and (min-width: 2736px) {
  .input-code-title {
    @apply text-[calc(24px_*_(2.19))] mb-[calc(1.25rem_*_(1824_/_768))];
  }

  .input-general {
    @apply h-[calc(70px_*_(1824_/_768))] border-[calc(3px_*_(2.19))];
  }

  .input-code {
    @apply rounded-[calc(10px_*_(2.19))] text-[calc(30px_*_(2.19))];
  }

  .input-code-order-lookup {
    @apply rounded-[calc(16px_*_(2.19))] text-[calc(40px_*_(2.19))];
  }
}
