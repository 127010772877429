.page-container {
  @apply flex flex-col pb-[42px] h-full;
}

.page-head-wrap {
  @apply h-[106px] pt-[27px] pb-[23px] px-[42px] grid grid-cols-4 gap-x-[26px] relative;
}

.page-divide-line {
  @apply h-[2px] w-full bg-midnight-200;
}

.page-body-wrap {
  @apply flex-1 pt-[31px] px-[42px] grid grid-cols-5 grid-rows-4 gap-5;
}

// Responsive
@media screen and (min-width: 2048px) {
  .page-head-wrap {
    @apply h-[calc(106px_*_(1536_/_768))] 
    pt-[calc(27px_*_(1.75))] 
    pb-[calc(23px_*_(1.75))] 
    px-[calc(42px_*_(1.75))]
    gap-x-[calc(26px_*_(1.75))];
  }

  .page-body-wrap {
    @apply pt-[calc(31px_*_(1.75))] px-[calc(42px_*_(1.75))] gap-[calc(1.25rem_*_(1.75))];
  }

  .page-divide-line {
    @apply h-[calc(2px_*_(1.75))];
  }
}

@media screen and (min-width: 2736px) {
  .page-head-wrap {
    @apply h-[calc(106px_*_(1824_/_768))]
    pt-[calc(27px_*_(2.19))] 
    pb-[calc(23px_*_(2.19))] 
    px-[calc(42px_*_(2.19))]
    gap-x-[calc(26px_*_(2.19))];
  }

  .page-body-wrap {
    @apply pt-[calc(31px_*_(2.19))] px-[calc(42px_*_(2.19))] gap-[calc(1.25rem_*_(2.19))];
  }

  .page-divide-line {
    @apply h-[calc(2px_*_(2.19))];
  }
}
