.icon-pizza {
  width: 31px;
  height: 31px;
}

.icon-wings {
  width: 36px;
  height: 32px;
}

.icon-bowl {
  width: 44px;
  height: 22px;
}

.icon-bread {
  width: 34px;
  height: 33px;
}

.icon-deserts {
  width: 33px;
  height: 24px;
}

.icon-drink {
  width: 22px;
  height: 31px;
}

.icon-flatbread {
  width: 38px;
  height: 38px;
}

.icon-pasta {
  width: 41px;
  height: 34px;
}

.icon-salads {
  width: 36px;
  height: 34px;
}

.icon-secret {
  width: 26px;
  height: 34px;
}

.icon-subs {
  width: 45px;
  height: 26px;
}

.icon-arrow {
  width: 13px;
  height: 8px;
}

.icon-mall {
  width: 28px;
  height: 18px;
}

@media (min-width: 2048px) {
  .icon-pizza {
    width: calc(31px * (2048 / 1366));
    height: calc(31px * (1536 / 768));
  }

  .icon-wings {
    width: calc(36px * (2048 / 1366));
    height: calc(32px * (1536 / 768));
  }

  .icon-bowl {
    width: calc(44px * (2048 / 1366));
    height: calc(22px * (1536 / 768));
  }

  .icon-bread {
    width: calc(34px * (2048 / 1366));
    height: calc(33px * (1536 / 768));
  }

  .icon-deserts {
    width: calc(33px * (2048 / 1366));
    height: calc(24px * (1536 / 768));
  }

  .icon-drink {
    width: calc(22px * (2048 / 1366));
    height: calc(31px * (1536 / 768));
  }

  .icon-flatbread {
    width: calc(38px * (2048 / 1366));
    height: calc(38px * (1536 / 768));
  }

  .icon-pasta {
    width: calc(41px * (2048 / 1366));
    height: calc(34px * (1536 / 768));
  }

  .icon-salads {
    width: calc(36px * (2048 / 1366));
    height: calc(34px * (1536 / 768));
  }

  .icon-secret {
    width: calc(26px * (2048 / 1366));
    height: calc(34px * (1536 / 768));
  }

  .icon-subs {
    width: calc(45px * (2048 / 1366));
    height: calc(26px * (1536 / 768));
  }

  .icon-arrow {
    width: calc(13px * (2048 / 1366));
    height: calc(8px * (1536 / 768));
  }

  .icon-mall {
    width: calc(28px * (2048 / 1366));
    height: calc(18px * (1536 / 768));
  }
}

@media (min-width: 2736px) {
  .icon-pizza {
    width: calc(31px * (2736 / 1366));
    height: calc(31px * (1824 / 768));
  }

  .icon-wings {
    width: calc(36px * (2736 / 1366));
    height: calc(32px * (1824 / 768));
  }

  .icon-bowl {
    width: calc(44px * (2736 / 1366));
    height: calc(22px * (1824 / 768));
  }

  .icon-bread {
    width: calc(34px * (2736 / 1366));
    height: calc(33px * (1824 / 768));
  }

  .icon-deserts {
    width: calc(33px * (2736 / 1366));
    height: calc(24px * (1824 / 768));
  }

  .icon-drink {
    width: calc(22px * (2736 / 1366));
    height: calc(31px * (1824 / 768));
  }

  .icon-flatbread {
    width: calc(38px * (2736 / 1366));
    height: calc(38px * (1824 / 768));
  }

  .icon-pasta {
    width: calc(41px * (2736 / 1366));
    height: calc(34px * (1824 / 768));
  }

  .icon-salads {
    width: calc(36px * (2736 / 1366));
    height: calc(34px * (1824 / 768));
  }

  .icon-secret {
    width: calc(26px * (2736 / 1366));
    height: calc(34px * (1824 / 768));
  }

  .icon-subs {
    width: calc(45px * (2736 / 1366));
    height: calc(26px * (1824 / 768));
  }

  .icon-arrow {
    width: calc(13px * (2736 / 1366));
    height: calc(8px * (1824 / 768));
  }

  .icon-mall {
    width: calc(28px * (2736 / 1366));
    height: calc(18px * (1824 / 768));
  }
}
