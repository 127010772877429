.page-container {
  @apply flex flex-col h-full;
}

.page-head-wrap {
  @apply h-[106px] pt-[27px] pb-[23px] px-[42px] grid grid-cols-5 gap-x-[26px] relative;
}

.page-divide-line {
  @apply h-[2px] w-full bg-midnight-200;
}

.page-body-wrap {
  @apply flex-1 pt-6 px-[42px] grid grid-cols-5 gap-5;
}

// Responsive
@media screen and (min-width: 2048px) {
  .page-head-wrap {
    @apply h-[calc(106px_*_(1536_/_768))] pt-[calc(27px_*_(1536_/_768))] pb-[calc(23px_*_(1536_/_768))] px-[calc(42px_*_(2048_/_1366))] gap-x-[calc(26px_*_(2048_/_1366))];
  }

  .page-body-wrap {
    @apply pt-[calc(24px_*_1536_/_768)] px-[calc(42px_*_(2048_/_1366))] gap-[calc(20px_*_1.75)];
  }
}

@media screen and (min-width: 2736px) {
  .page-head-wrap {
    @apply h-[calc(106px_*_(1824_/_768))] pt-[calc(27px_*_(1824_/_768))] pb-[calc(23px_*_(1824_/_768))] px-[calc(42px_*_(2736_/_1366))] gap-x-[calc(26px_*_(2736_/_1366))];
  }

  .page-body-wrap {
    @apply pt-[calc(24px_*_(1824_/_768))] px-[calc(42px_*_(2736_/_1366))] gap-[calc(20px_*_2.19)];
  }
}
