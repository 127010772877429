.login-container {
  @apply w-screen h-screen min-w-[1366px] flex items-center justify-center bg-common-50;
}

.login-banner {
  @apply w-[655px] h-full bg-gradient-to-b from-common-10 to-common-20 relative flex flex-col items-center justify-between;
}

.login-banner-header,
.login-banner-content,
.login-banner-footer {
  @apply w-full;
}

.login-banner-header {
  @apply pt-[19px] pl-[22px];
}

.login-banner-header-logo {
  @apply py-4 px-3.5 w-14 h-14 rounded-full bg-common-40;
}

.login-banner-header-logo img {
  @apply w-full h-full;
}

.login-banner-content {
  @apply flex-1 pt-20 flex flex-col items-center;
}

.login-banner-greeting-wrapper {
  @apply mb-9;
}

.login-banner-date-wrapper {
  @apply mb-[33px];
}

.login-banner-footer {
  @apply flex items-center;
}

.login-banner-footer-title {
  @apply bg-common-40 py-2.5 pl-[30px] pr-[40px] w-[149px] rounded-tr-2xl text-white text-xl-700-normal truncate;
}

.login-banner-footer-title-txt {
  @apply w-full text-white text-xl-700-normal truncate;
}

.login-content {
  @apply flex-1 h-full px-[184px] pt-[76px] pb-[20px] overflow-hidden;
}

.login-content-station-code {
  @apply mb-5;
}

.login-content-keyboard {
  @apply h-full grid grid-cols-3 gap-5 mb-5 px-[9px];
}

.login-content-actions {
  @apply grid grid-cols-2 gap-5 px-[9px];
}

.login-content-form {
  @apply h-auto mb-[77px];
}

.login-content-footer {
  @apply text-base-700-normal text-common-60 text-center;
}
