.left-content-title {
  @apply text-24-700-normal text-white mb-3;
}

.left-content-dropdown-wrap {
  @apply mt-[78px] ml-8 mb-5;
}

.dropdown-wrap {
  @apply flex justify-start items-center gap-[72px] text-white;
}

.table-wrap {
  @apply relative overflow-x-auto;
}

@media screen and (min-width: 2048px) {
  .left-content-title {
    @apply text-[calc(24px_*_(1.75))] mb-[calc(0.75_*_(1536_/_768))];
  }

  .left-content-dropdown-wrap {
    @apply mt-[calc(78px_*_(1536_/_768))] ml-[calc(2rem_*_(2048_/_1366))] mb-[calc(1.25rem_*_(2048_/_1366))];
  }

  .dropdown-wrap {
    @apply gap-[calc(72px_*_(1.75))];
  }
}

@media screen and (min-width: 2736px) {
  .left-content-title {
    @apply text-[calc(24px_*_(2.19))] mb-[calc(0.75_*_(1824_/_768))];
  }

  .left-content-dropdown-wrap {
    @apply mt-[calc(78px_*_(1824_/_768))] ml-[calc(2rem_*_(2736_/_1366))] mb-[calc(1.25rem_*_(2736_/_1366))];
  }

  .dropdown-wrap {
    @apply gap-[calc(72px_*_(2.19))];
  }
}
