.page-container {
  @apply flex flex-col pb-[42px] h-full;
}

.page-head-wrap {
  @apply h-[106px] pt-[27px] pb-[23px] px-[42px] grid grid-cols-4 gap-x-[26px] relative;
}

.page-divide-line {
  @apply h-[2px] w-full bg-midnight-200;
}

.page-body-wrap {
  @apply flex-1 pt-[31px] px-[42px] grid grid-cols-5 grid-rows-4 gap-x-5 gap-y-[23px];
}

// Responsive
@media screen and (min-width: 2048px) {
  .page-container {
    @apply pb-[calc(42px_*_(1536_/_768))];
  }

  .page-head-wrap {
    @apply h-[calc(106px_*_(1536_/_768))] 
    pt-[calc(27px_*_(1536_/_768))] 
    pb-[calc(23px_*_(1536_/_768))] 
    px-[calc(42px_*_(2048_/_1366))]
    gap-x-[calc(26px_*_(2048_/_1366))];
  }

  .page-body-wrap {
    @apply pt-[calc(31px_*_(1536_/_768))] 
    px-[calc(42px_*_(2048_/_1366))] 
    gap-x-[calc(20px_*_(2048_/_1366))] 
    gap-y-[calc(23px_*_(1536_/_768))];
  }

  .page-divide-line {
    @apply h-[calc(2px_*_(1536_/_768))];
  }
}

@media screen and (min-width: 2736px) {
  .page-head-wrap {
    @apply h-[calc(106px_*_(1824_/_768))]
    pt-[calc(27px_*_(1824_/_768))] 
    pb-[calc(23px_*_(1824_/_768))] 
    px-[calc(42px_*_(2736_/_1366))]
    gap-x-[calc(26px_*_(2736_/_1366))];
  }

  .page-body-wrap {
    @apply pt-[calc(31px_*_(1824_/_768))] 
    px-[calc(42px_*_(2736_/_1366))] 
    gap-x-[calc(20px_*_(2736_/_1366))] 
    gap-y-[calc(23px_*_(1824_/_768))];
  }

  .page-divide-line {
    @apply h-[calc(2px_*_(1824_/_768))];
  }
}
