// Price
.price-row-wrap {
  @apply grid grid-cols-11 h-[57px] mb-[2px] gap-x-[2px];
}

.price-format-display {
  @apply flex flex-col justify-center pl-5 text-xl-700-normal;
}

.price-title {
  @apply text-midnight-50;
}

.price-sub-total-tax-wrap {
  @apply col-span-7 grid grid-cols-7;
  background: linear-gradient(160deg, #0e0a24 -70.21%, #2f2957 113.93%);
}

.price-sub-total {
  @apply col-span-3;
}

.price-tax {
  @apply col-span-4;
}

.price-total {
  @apply bg-midnight-400 col-span-4;
}

// Responsive
@media screen and (min-width: 2048px) {
  .order-method-wrap {
    @apply h-[calc(45px_*_(1536_/_768))] gap-x-[calc(2px_*_(2048_/_1366))] mt-[calc(2px_*_(1536_/_768))];
  }

  .price-row-wrap {
    @apply h-[calc(57px_*_(1536_/_768))] mb-[calc(2px_*_(1536_/_768))] gap-x-[calc(2px_*_(2048_/_1366))];
  }

  .price-format-display {
    @apply text-[calc(18px_*_1.75)];
  }
}

@media screen and (min-width: 2736px) {
  .order-method-wrap {
    @apply h-[calc(45px_*_(1824_/_768))] gap-x-[calc(2px_*_(2736_/_1366))] mt-[calc(2px_*_(1824_/_768))];
  }

  .price-row-wrap {
    @apply h-[calc(57px_*_(1824_/_768))] mb-[calc(2px_*_(1824_/_768))] gap-x-[calc(2px_*_(2736_/_1366))];
  }

  .price-format-display {
    @apply text-[calc(18px_*_2.19)];
  }
}
