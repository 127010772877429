.date-title-container {
  @apply justify-center flex-shrink-0;
}
.date-title-container-login {
  @apply flex w-[328px] h-[38px];
}
.date-title-container-welcome {
  @apply flex w-[281px] h-[32.555px];
}
.date-title-login {
  @apply text-28-400-normal text-common-30;
}
.date-title-welcome {
  @apply text-24-400-normal text-white;
}

// Responsive
@media screen and (min-width: 2048px) {
  .date-title-container-login {
    @apply w-[calc(328px_*_(2048_/_1366))] h-[calc(38px_*_(1536_/_768))];
  }

  .date-title-container-welcome {
    @apply w-[calc(281px_*_(2048_/_1366))] h-[calc(32.555px_*_(1536_/_768))];
  }

  .date-title-login {
    @apply text-[calc(28px_*_(1.75))];
  }

  .date-title-welcome {
    @apply text-[calc(24px_*_(1.75))];
  }
}

@media screen and (min-width: 2736px) {
  .date-title-container-login {
    @apply w-[calc(328px_*_(2736_/_1366))] h-[calc(38px_*_(1824_/_768))];
  }

  .date-title-container-welcome {
    @apply w-[calc(281px_*_(2736_/_1366))] h-[calc(32.555px_*_(1824_/_768))];
  }

  .date-title-login {
    @apply text-[calc(28px_*_(2.19))];
  }

  .date-title-welcome {
    @apply text-[calc(24px_*_(2.19))];
  }
}
