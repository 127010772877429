.content-button {
  @apply w-full h-full pl-5 pr-5 bg-midnight-400 rounded-lg text-white text-base-700-normal;
}

// Responsive
@media screen and (min-width: 2048px) {
  .content-button {
    @apply pl-[calc(20px_*_(2048_/_1366))] 
    pr-[calc(20px_*_(2048_/_1366))] 
    text-[calc(16px_*_(1.75))] 
    rounded-[calc(8px_*_(1.75))];
  }
}

@media screen and (min-width: 2736px) {
  .content-button {
    @apply pl-[calc(20px_*_(2736_/_1366))]
    pr-[calc(20px_*_(2736_/_1366))]
    text-[calc(16px_*_(2.19))]
    rounded-[calc(8px_*_(2.19))];
  }
}
