.button-top {
  @apply h-full w-full flex flex-col justify-center items-center gap-0.5 bg-midnight-400 rounded-lg;
}

.button-text {
  @apply text-base-700-110 text-white;
}

.button-text-sub-title {
  @apply opacity-50;
}

.active {
  @apply bg-[#0E8476];
}

// Responsive
@media screen and (min-width: 2048px) {
  .button-top {
    @apply gap-[calc(2px_*_(1.75))] rounded-[calc(8px_*_(1.75))];
  }

  .button-text {
    @apply text-[calc(16px_*_(1.75))];
  }
}

@media screen and (min-width: 2736px) {
  .button-top {
    @apply gap-[calc(2px_*_(2.19))] rounded-[calc(8px_*_(2.19))];
  }

  .button-text {
    @apply text-[calc(16px_*_(2.19))];
  }
}
