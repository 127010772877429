.footer-container {
  @apply h-full flex justify-between items-center;
}

.footer-options {
  @apply h-full py-[15px] flex justify-start items-center gap-[51px];
}

.footer-options-button {
  @apply h-full py-[15px] flex justify-start items-center gap-6;
}

.footer-options-button button {
  @apply px-5 min-w-[125px];
}

.footer-options-button button:last-child {
  @apply px-5 min-w-[242px];
}

.footer-options-button-icon-wrap {
  @apply h-full flex justify-between items-center gap-[10px];
}

.footer-options-button-icon {
  @apply w-[calc(28px_*_(2048_/_1366))];
}

// Responsive
@media screen and (min-width: 2048px) {
  .footer-options {
    @apply h-full py-[calc(15px_*_(1536_/_768))] flex justify-start items-center gap-[calc(51px_*_(2048_/_1366))];
  }

  .footer-options-button {
    @apply h-full py-[calc(15px_*_(1536_/_768))] flex justify-start items-center gap-[calc(24px_*_(2048_/_1366))];
  }

  .footer-options-button button {
    @apply px-[calc(20px_*_(2048_/_1366))] min-w-[calc(125px_*_(2048_/_1366))];
  }

  .footer-options-button button:last-child {
    @apply px-[calc(20px_*_(2048_/_1366))] min-w-[calc(242px*_(2048_/_1366))];
  }

  .footer-options-button-icon-wrap {
    @apply h-full flex justify-between items-center gap-[calc(10px_*_(2048_/_1366))];
  }
}

@media screen and (min-width: 2736px) {
  .footer-options {
    @apply h-full py-[calc(15px_*_(1824_/_768))] flex justify-start items-center gap-[calc(51px_*_(2736_/_1366))];
  }

  .footer-options-button {
    @apply h-full py-[calc(15px_*_(1824_/_768))] flex justify-start items-center gap-[calc(24px_*_(2736_/_1366))];
  }

  .footer-options-button button {
    @apply px-[calc(20px_*_(2736_/_1366))] min-w-[calc(125px_*_(2736_/_1366))];
  }

  .footer-options-button button:last-child {
    @apply px-[calc(20px_*_(2736_/_1366))] min-w-[calc(242px*_(2736_/_1366))];
  }

  .footer-options-button-icon-wrap {
    @apply h-full flex justify-between items-center gap-[calc(10px_*_(2736_/_1366))];
  }

  .footer-options-button-icon {
    @apply w-[calc(28px_*_(2736_/_1366))];
  }
}
