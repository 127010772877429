.dropdown-container {
  @apply min-w-[118px] flex flex-col bg-midnight-500;
}

.dropdown-title {
  @apply text-base-700-normal text-common-80;
}

.dropdown-display-value {
  @apply flex justify-between items-center;
}

.dropdown-value {
  @apply text-xl-300-normal text-white;
}

@media screen and (min-width: 2048px) {
  .dropdown-container {
    @apply min-w-[calc(118px*_(2048_/_1366))];
  }

  .dropdown-title {
    @apply text-[calc(16px_*_(1.75))];
  }

  .dropdown-value {
    @apply text-[calc(18px_*_(1.75))];
  }

  .dropdown-display-value img {
    @apply w-[calc(13px_*_(2048_/_1366))] h-[calc(13px_*_(1536_/_768))];
  }
}

@media screen and (min-width: 2736px) {
  .dropdown-container {
    @apply min-w-[calc(118px*_(2736_/_1366))];
  }

  .dropdown-title {
    @apply text-[calc(16px_*_(2.19))];
  }

  .dropdown-value {
    @apply text-[calc(18px_*_(2.19))];
  }

  .dropdown-display-value img {
    @apply w-[calc(13px_*_(2736_/_1366))] h-[calc(13px_*_(1824_/_768))];
  }
}
