.delivery-container {
  @apply flex flex-col min-h-[163px] justify-between items-center bg-white py-8;
}

.delivery-icon-wrap {
  @apply h-full flex justify-center items-center;
}

.delivery-text-content {
  @apply mt-5 text-22-700-normal text-midnight-500;
}

// Responsive
@media screen and (min-width: 2048px) {
  .delivery-container {
    @apply py-[calc(32px_*_(1536_/_768))];
  }

  .delivery-icon {
    @apply w-[calc(53px_*_(2048_/_1366))];
  }

  .delivery-text-content {
    @apply text-[calc(22px_*_(1.75))];
  }
}

@media screen and (min-width: 2736px) {
  .delivery-container {
    @apply py-[calc(32px_*_(1824_/_768))];
  }

  .delivery-icon {
    @apply w-[calc(53px_*_(2376_/_1366))];
  }

  .delivery-text-content {
    @apply text-[calc(22px_*_(2.19))];
  }
}
