.input-password {
  @apply bg-common-170 w-[286px] h-[40px] mb-[11px] rounded-[4px] text-midnight-500 px-4 outline-none;
}

.input-password-message {
  @apply text-sandstone-500 w-full text-center text-xl-300-normal;
}

// Responsive
@media screen and (min-width: 2048px) {
  .input-password {
    @apply w-[calc(286px_*_(2048_/_1366))] 
    h-[calc(40px_*_(1536_/_768))] 
    mb-[calc(11px_*_(1536_/_768))] 
    rounded-[calc(4px_*_(1.75))]
    px-[calc(16px_*_(2048_/_1366))];
  }

  .input-password-message {
    @apply text-[calc(18px_*_(1.75))];
  }
}

@media screen and (min-width: 2736px) {
  .input-password {
    @apply w-[calc(286px_*_(2736_/_1366))] 
    h-[calc(40px_*_(1824_/_768))] 
    mb-[calc(11px_*_(1824_/_1366))] 
    rounded-[calc(4px_*_(2.19))]
    px-[calc(16px_*_(2736_/_1366))];
  }

  .input-password-message {
    @apply text-[calc(18px_*_(2.19))];
  }
}
