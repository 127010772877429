.footer-button {
  @apply w-full h-full text-xl-700-normal flex justify-center items-center;
}

.footer-button-arrow {
  @apply w-[51px] text-midnight-500 rounded-xl border-midnight-500 border-2 border-solid;
}

.footer-button-action {
  @apply text-midnight-500 rounded-xl border-midnight-500 border-2 border-solid shadow-[0px_2.719px_3.625px_0px_rgba(26,19,70,0.12)];
}

.footer-button-add {
  @apply text-jade-500 rounded-xl border-jade-500 border-2 border-solid shadow-[0px_2.719px_3.625px_0px_rgba(26,19,70,0.12)];
}

.footer-button-action.button-action-active {
  @apply text-jade-500 border-jade-500;
}

.footer-button-send {
  @apply w-full bg-jade-500 text-white rounded-2xl;
}

.footer-button-disable {
  @apply text-midnight-500 text-xl-700-normal rounded-2xl border-midnight-500 border-2 border-solid shadow-[0px_2.719px_3.625px_0px_rgba(26,19,70,0.12)];
}

// Responsive
@media screen and (min-width: 2048px) {
  .footer-button {
    @apply text-[calc(18px_*_1.75)];
  }

  .footer-button-arrow {
    @apply w-[calc(51px_*_(2048_/_1366))] rounded-[calc(12px_*_1.75)] border-[calc(2px_*_1.75)];
  }

  .footer-button-action {
    @apply rounded-[calc(12px_*_1.75)] border-[calc(2px_*_1.75)];
  }

  .footer-button-send {
    @apply rounded-[calc(16px_*_1.75)];
  }

  .footer-button-add {
    @apply rounded-[calc(12px_*_1.75)] border-[calc(2px_*_1.75)];
  }

  .footer-button-disable {
    @apply text-[calc(18px_*_(1.75))] rounded-[calc(16px_*_(1.75))] border-[calc(2px_*_(1.75))];
  }
}

@media screen and (min-width: 2736px) {
  .footer-button {
    @apply text-[calc(18px_*_2.19)];
  }

  .footer-button-arrow {
    @apply w-[calc(51px_*_(2736_/_1366))] rounded-[calc(12px_*_2.19)] border-[calc(2px_*_2.19)];
  }

  .footer-button-action {
    @apply rounded-[calc(12px_*_2.19)] border-[calc(2px_*_2.19)];
  }

  .footer-button-send {
    @apply rounded-[calc(16px_*_2.19)];
  }

  .footer-button-add {
    @apply rounded-[calc(12px_*_2.19)] border-[calc(2px_*_2.19)];
  }

  .footer-button-disable {
    @apply text-[calc(18px_*_(2.19))] rounded-[calc(16px_*_(2.19))] border-[calc(2px_*_(2.19))];
  }
}
