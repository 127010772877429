.footer-order-container {
  @apply h-full flex justify-between py-[15px] pl-[30px] pr-[30px];
}

.footer-order-group-button {
  @apply h-full grid grid-cols-6 gap-[19px];

  > div {
    @apply w-[162px];
  }
}

.footer-order-save-return {
  @apply w-[173px];
}

@media screen and (min-width: 2048px) {
  .footer-order-container {
    @apply py-[calc(15px_*_(1536_/_768))] pl-[calc(30px_*_(2048_/_1366))] pr-[calc(30px_*_(2048_/_1366))];
  }

  .footer-order-group-button {
    @apply gap-[calc(19px_*_(1.75))];

    > div {
      @apply w-[calc(162px_*_(2048_/_1366))];
    }
  }

  .footer-order-save-return {
    @apply w-[calc(173px_*_(2048_/_1366))];
  }
}

@media screen and (min-width: 2736px) {
  .footer-order-container {
    @apply py-[calc(15px_*_(1824_/_768))] pl-[calc(30px_*_(2736_/_1366))] pr-[calc(30px_*_(2736_/_1366))];
  }

  .footer-order-group-button {
    @apply gap-[calc(19px_*_(2.19))];

    > div {
      @apply w-[calc(162px_*_(2736_/_1366))];
    }
  }

  .footer-order-save-return {
    @apply w-[calc(173px_*_(2736_/_1366))];
  }
}
