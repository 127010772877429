.food-order-container {
  @apply bg-white w-full py-[11px] pl-[18px] pr-[15px] grid grid-cols-2;
}

.food-order-content {
  @apply flex items-center;
}

.food-order-title,
.food-order-title-price {
  @apply text-midnight-500 text-base-700-normal;
}

.food-order-title {
  @apply flex-1 truncate;
}

.food-order-select-container {
  @apply flex items-center justify-between;
}

.food-order-btn-delete {
  @apply px-4 py-2 bg-sandstone-500/10 rounded-xl;
}

// List topping
.food-order-list-topping {
  @apply w-full h-[49px] flex items-center;
}

.food-order-del-topping {
  @apply basis-[50px] h-full bg-sandstone-500/10 flex justify-center items-center;
}

.food-order-topping-info {
  @apply flex-1 h-full bg-common-190 grid grid-cols-9 items-center;
}

.topping-info {
  @apply pl-[14px] col-span-4;
}

.topping-price {
  @apply pl-[19px] col-span-5;
}

.text-style {
  @apply text-sm-700-normal text-midnight-500 italic truncate;
}

// Responsive
@media screen and (min-width: 2048px) {
  .food-order-container {
    @apply py-[calc(11px_*_(1536_/_768))] pl-[calc(18px_*_(2048_/_1366))] pr-[calc(15px_*_(2048_/_1366))];
  }

  .food-order-title,
  .food-order-title-price {
    @apply text-[calc(16px_*_1.75)];
  }

  .food-order-btn-delete {
    @apply px-[calc(16px_*_(2048_/_1366))] py-[calc(8px_*_(1536_/_768))] rounded-[calc(12px_*_1.75)];
  }

  .food-order-list-topping {
    @apply h-[calc(49px_*_(1536_/_768))];
  }

  .food-order-del-topping {
    @apply basis-[calc(50px_*_(2048_/_1366))];
  }

  .topping-info {
    @apply pl-[calc(14px_*_(2048_/_1366))];
  }

  .topping-price {
    @apply pl-[calc(19px_*_(2048_/_1366))];
  }

  .text-style {
    @apply text-[calc(15px_*_1.75)];
  }

  .icon-trash {
    @apply w-[calc(15px_*_(2048_/_1366))];
  }

  .icon-cross {
    @apply w-[calc(11px_*_(2048_/_1366))];
  }
}

@media screen and (min-width: 2736px) {
  .food-order-container {
    @apply py-[calc(11px_*_(1824_/_768))] pl-[calc(18px_*_(2736_/_1366))] pr-[calc(15px_*_(2736_/_1366))];
  }

  .food-order-title,
  .food-order-title-price {
    @apply text-[calc(16px_*_2.19)];
  }

  .food-order-btn-delete {
    @apply px-[calc(16px_*_(2736_/_1366))] py-[calc(8px_*_(1824_/_768))] rounded-[calc(12px_*_2.19)];
  }

  .food-order-list-topping {
    @apply h-[calc(49px_*_(1824_/_768))];
  }

  .food-order-del-topping {
    @apply basis-[calc(50px_*_(2736_/_1366))];
  }

  .topping-info {
    @apply pl-[calc(14px_*_(2736_/_1366))];
  }

  .topping-price {
    @apply pl-[calc(19px_*_(2736_/_1366))];
  }

  .text-style {
    @apply text-[calc(15px_*_2.19)];
  }

  .icon-trash {
    @apply w-[calc(15px_*_(2736_/_1366))];
  }

  .icon-cross {
    @apply w-[calc(11px_*_(2736_/_1366))];
  }
}
