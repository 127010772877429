.greetings-container {
  @apply flex flex-col justify-center items-center;
}

.greetings-icon {
  @apply mb-3;
}

.greetings-title {
  @apply text-28-700-normal text-white;
}

.greetings-title.welcome {
  @apply text-28-700-normal text-jade-500;
}

// Responsive
@media screen and (min-width: 2048px) {
  .greetings-icon {
    @apply mb-[calc(18px_*_(1536_/_768))];
  }

  .greetings-title {
    @apply text-[calc(18px_*_(1.75))];
  }

  .greetings-title.welcome {
    @apply text-[calc(18px_*_(1.75))];
  }
}

@media screen and (min-width: 2736px) {
  .greetings-icon {
    @apply mb-[calc(18px_*_(1824_/_768))];
  }

  .greetings-title {
    @apply text-[calc(18px_*_(2.19))];
  }

  .greetings-title.welcome {
    @apply text-[calc(18px_*_(2.19))];
  }
}
