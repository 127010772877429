.topping-select-container {
  @apply w-full flex flex-col justify-center items-center;
}

.topping-name {
  @apply text-base-700-normal text-white;
}

// Selection
.topping-select-wrap {
  @apply mt-[3px] w-full relative grid grid-cols-3 gap-x-[3.5px] h-[36px];
}

.btn-topping-common-style {
  @apply text-base-700-normal text-white bg-midnight-400;
}

.list-box-btn {
  @apply w-full h-full;
}

.btn-topping-text {
  @apply w-full h-full text-base-700-normal flex justify-center items-center truncate text-white;
}

.topping-left {
  @apply rounded-l-full;
}

.topping-right {
  @apply rounded-r-full;
}

.btn-topping-active {
  @apply bg-jade-700;
}

// Option list
.topping-list-selector {
  @apply absolute top-[40px] left-0 right-0 bg-midnight-500 rounded-[6px] overflow-hidden z-10 flex flex-col gap-y-[1px];
}

.topping-selector-item {
  @apply py-[9px] text-center text-17-900-normal bg-jade-500 text-white cursor-pointer;
}

.topping-selector-item:hover,
.topping-selector-item-active {
  @apply bg-jade-700;
}

// Responsive
@media screen and (min-width: 2048px) {
  .topping-name {
    @apply text-[calc(16px_*_(1.75))];
  }

  .topping-select-wrap {
    @apply mt-[calc(3px_*_(1536_/_768))] gap-x-[calc(3.5px_*_(2048_/_1366))] h-[calc(36px_*_(1536_/_768))];
  }

  .btn-topping-common-style {
    @apply text-[calc(16px_*_(1.75))];
  }

  .btn-topping-text {
    @apply text-[calc(16px_*_(1.75))];
  }

  .topping-list-selector {
    @apply top-[calc(40px_*_(1536_/_768))] rounded-[calc(6px_*_(1.75))] gap-y-[calc(1px_*_(1536_/_768))];
  }

  .topping-selector-item {
    @apply py-[calc(9px_*_(1536_/_768))] text-[calc(17px_*_(1.75))];
  }
}

@media screen and (min-width: 2736px) {
  .topping-name {
    @apply text-[calc(16px_*_(2.19))];
  }

  .topping-select-wrap {
    @apply mt-[calc(3px_*_(1824_/_768))] gap-x-[calc(3.5px_*_(2736_/_1366))] h-[calc(36px_*_(1824_/_768))];
  }

  .btn-topping-common-style {
    @apply text-[calc(16px_*_(2.19))];
  }

  .btn-topping-text {
    @apply text-[calc(16px_*_(2.19))];
  }

  .topping-list-selector {
    @apply top-[calc(40px_*_(1824_/_768))] rounded-[calc(6px_*_(2.19))] gap-y-[calc(1px_*_(1824_/_768))];
  }

  .topping-selector-item {
    @apply py-[calc(9px_*_(1824_/_768))] text-[calc(17px_*_(2.19))];
  }
}
