.selector-container {
  @apply w-full rounded-lg z-10;
}

.selector-btn-wrap {
  @apply w-full h-full max-h-14 rounded-lg;
}

.selector-btn {
  @apply w-full h-full max-h-14 rounded-lg flex justify-between items-center py-[9px] pl-5 pr-6 bg-midnight-400;
}

.open-dropdown-style {
  @apply bg-white rounded-t-lg rounded-b-none;
}

.bg-has-value {
  @apply bg-midnight-300;
}

.disable-style {
  @apply opacity-50;
}

.selector-value-group {
  @apply flex flex-col max-w-[80%] text-left;
}

.text-style {
  @apply text-base-700-110 text-white;
}

.open-dropdown-text-style {
  @apply text-base-700-110 text-jade-500;
}

.selector-title {
  @apply opacity-50;
}

.selector-arrow-rotate {
  @apply rotate-180;
}

.selector-list {
  @apply absolute top-[83px] left-[42px] right-[42px] min-h-[340px] bg-white py-[25px] px-[33px] rounded-b-lg grid grid-cols-4 grid-rows-4 gap-x-[30px] gap-y-[11px];
  // @apply absolute top-[83px] left-[42px] right-[42px] min-h-[340px] h-[calc(100vh_-_340px)] bg-white py-[25px] px-[33px] rounded-b-lg grid grid-cols-4 grid-rows-4 gap-x-[30px] gap-y-[11px];
}

.selector-item {
  @apply py-2 px-5 cursor-pointer border-jade-500 border-2 rounded-xl flex justify-center items-center text-xl-700-110 text-jade-500 bg-common-120 shadow-[0px_2.719px_3.625px_0px_rgba(26,19,70,0.12)];
}

// Responsive
@media screen and (min-width: 2048px) {
  .selector-container {
    @apply rounded-[calc(8px_*_1.75)];
  }

  .selector-btn-wrap {
    @apply max-h-[calc(56px_*_(1536_/_768))] rounded-[calc(8px_*_1.75)];
  }

  .selector-btn {
    @apply max-h-[calc(56px_*_(1536_/_768))] rounded-[calc(8px_*_1.75)] py-[calc(9px_*_(1536_/_768))] pl-[calc(20px_*_(2048_/_1366))] pr-[calc(24px_*_(2048_/_1366))];
  }

  .open-dropdown-style {
    @apply rounded-t-[calc(8px_*_(1536_/_768))] rounded-b-none;
  }

  .text-style {
    @apply text-[calc(16px_*_1.75)];
  }

  .open-dropdown-text-style {
    @apply text-[calc(16px_*_1.75)];
  }

  .selector-list {
    @apply top-[calc(83px_*_(1536_/_768))] left-[calc(42px_*_(2048_/_1366))] right-[calc(42px_*_(2048_/_1366))] py-[calc(25px_*_(1536_/_768))] px-[calc(33px_*_(2048_/_1366))] rounded-b-[calc(8px_*_(1536_/_768))] gap-x-[calc(30px_*_(2048_/_1366))] gap-y-[calc(11px_*_(1536_/_768))];
    // @apply top-[calc(83px_*_(1536_/_768))] left-[calc(42px_*_(2048_/_1366))] right-[calc(42px_*_(2048_/_1366))] h-[calc(100vh_-_(345px_*_(1536_/_768)))] py-[calc(25px_*_(1536_/_768))] px-[calc(33px_*_(2048_/_1366))] rounded-b-[calc(8px_*_(1536_/_768))] gap-x-[calc(30px_*_(2048_/_1366))] gap-y-[calc(11px_*_(1536_/_768))];
  }

  .selector-item {
    @apply py-[calc(8px_*_(1536_/_768))] px-[calc(20px_*_(2048_/_1366))] border-[calc(2px_*_1.75)] rounded-[calc(12px_*_1.75)] text-[calc(18px_*_1.75)];
  }
}

@media screen and (min-width: 2736px) {
  .selector-container {
    @apply rounded-[calc(8px_*_2.19)];
  }

  .selector-btn-wrap {
    @apply max-h-[calc(56px_*_(1824_/_768))] rounded-[calc(8px_*_2.19)];
  }

  .selector-btn {
    @apply max-h-[calc(56px_*_(1824_/_768))] rounded-[calc(8px_*_2.19)] py-[calc(9px_*_(1824_/_768))] pl-[calc(20px_*_(2736_/_1366))] pr-[calc(24px_*_(2736_/_1366))];
  }

  .open-dropdown-style {
    @apply rounded-t-[calc(8px_*_(1824_/_768))] rounded-b-none;
  }

  .text-style {
    @apply text-[calc(16px_*_2.19)];
  }

  .open-dropdown-text-style {
    @apply text-[calc(16px_*_2.19)];
  }

  .selector-list {
    @apply top-[calc(83px_*_(1824_/_768))] left-[calc(42px_*_(2736_/_1366))] right-[calc(42px_*_(2736_/_1366))] py-[calc(25px_*_(1824_/_768))] px-[calc(33px_*_(2736_/_1366))] rounded-b-[calc(8px_*_(1824_/_768))] gap-x-[calc(30px_*_(2736_/_1366))] gap-y-[calc(11px_*_(1824_/_768))];
  }

  .selector-item {
    @apply py-[calc(8px_*_(1824_/_768))] px-[calc(20px_*_(2736_/_1366))] border-[calc(2px_*_2.19)] rounded-[calc(12px_*_2.19)] text-[calc(18px_*_2.19)];
  }
}
