.order-lookup-layout-container {
  @apply w-screen h-screen flex flex-col min-w-[1366px];
}

.order-lookup-content-wrap {
  @apply relative bg-midnight-500 flex-1 h-full;
}

.order-lookup-content-layout,
.order-lookup-content-left,
.order-lookup-content-right {
  @apply h-full;
}

.order-lookup-content-layout {
  @apply grid grid-cols-[640px_1fr];
}

.order-lookup-back-button-wrap {
  @apply absolute top-0 left-0;
}

.order-lookup-time-clock-wrap {
  @apply absolute top-0 right-0;
}

.footer-wrap {
  @apply w-full h-[90px];
}

.order-lookup-content-left {
  @apply w-full h-full;
}

.order-lookup-content-right {
  @apply w-full h-full pt-[50px] px-[164px] flex flex-col items-center;
}

.order-lookup-search-wrap {
  @apply mb-6;
}

.order-lookup-search-options {
  @apply flex items-center gap-5;
}

.order-lookup-search-title {
  @apply text-20-700-normal text-white mb-5;
}

.order-lookup-search-button {
  @apply py-3 min-h-[62px] text-white rounded-2xl text-xl-700-normal w-[119px];
}

.order-lookup-search-button.active {
  @apply bg-jade-500;
}

.order-lookup-search-button.default {
  @apply bg-transparent border-2 border-common-130;
}

.order-lookup-calculator {
  @apply max-w-[399px] flex flex-col items-center;
}

.order-lookup-input-form {
  @apply mb-5 w-full;
}

.order-lookup-keyboard-items {
  @apply grid grid-cols-3 gap-5 max-w-[325px];
}

// Responsive
@media screen and (min-width: 2048px) {
  .order-lookup-layout-container {
    @apply min-w-[calc(1366px_*_(2048_/_1366))];
  }

  .footer-wrap {
    @apply h-[calc(90px_*_(1536_/_768))];
  }

  .order-lookup-content-layout {
    // @apply flex;
    @apply grid-cols-[calc(640px_*_(2048_/_1366))_1fr];
  }

  .order-lookup-content-right {
    @apply pt-[calc(50px_*_(1536_/_768))] px-[calc(164px_*_(2048_/_1366))];
  }

  .order-lookup-search-wrap {
    @apply mb-[calc(1.5rem_*_(1536_/_768))];
  }

  .order-lookup-search-options {
    @apply gap-[calc(1.25rem_*_(1.75))];
  }

  .order-lookup-search-wrap {
    @apply mb-[calc(1.5rem_*_(1536_/_768))];
  }

  .order-lookup-search-title {
    @apply text-[calc(20px_*_(1.75))] mb-[calc(1.25rem*_(1536_/_768))];
  }

  .order-lookup-search-button {
    @apply py-[calc(0.75rem_*_(1536_/_768))] 
    min-h-[calc(62px_*_1.75)] 
    rounded-[calc(1rem_*_(1.75))] 
    text-[calc(18px_*_(1.75))] 
    w-[calc(119px_*_1.75)];
  }

  .order-lookup-search-button.default {
    @apply border-[calc(2px_*_(2048_/_1366))];
  }

  .order-lookup-calculator {
    @apply max-w-[calc(399px_*_1.75)];
  }

  .order-lookup-input-form {
    @apply mb-[calc(1.25rem_*_(1536_/_768))];
  }

  .order-lookup-keyboard-items {
    @apply gap-[calc(1.25rem_*_(1.75))] max-w-[calc(325px_*_1.75)];
  }

  .order-lookup-keyboard-items button {
    @apply min-h-[calc(70px_*_1.75)] min-w-[calc(95px_*_1.75)];
  }
}

@media screen and (min-width: 2736px) {
  .order-lookup-layout-container {
    @apply min-w-[calc(1366px_*_(2736_/_1366))];
  }

  .footer-wrap {
    @apply h-[calc(90px_*_(1824_/_768))];
  }

  .order-lookup-content-layout {
    // @apply flex;
    @apply grid-cols-[calc(640px_*_(2736_/_1366))_1fr];
  }

  .order-lookup-content-right {
    @apply pt-[calc(50px_*_(1824_/_768))] px-[calc(164px_*_(2736_/_1366))];
  }

  .order-lookup-search-options {
    @apply gap-[calc(1.25rem_*_(2.19))];
  }

  .order-lookup-search-wrap {
    @apply mb-[calc(1.5rem_*_(1824_/_768))];
  }

  .order-lookup-search-title {
    @apply text-[calc(20px_*_(2.19))] text-white mb-5;
  }

  .order-lookup-search-button {
    @apply py-[calc(0.75_*_(1824_/_768))] 
    min-h-[calc(62px_*_2.19)]
    rounded-[calc(1rem_*_(2.19))] 
    text-[calc(18px_*_(2.19))] 
    w-[calc(119px_*_2.19)];
  }

  .order-lookup-search-button.default {
    @apply border-[calc(2px_*_(2736_/_1366))];
  }

  .order-lookup-calculator {
    @apply max-w-[calc(399px_*_2.19)];
  }

  .order-lookup-input-form {
    @apply mb-[calc(1.25rem_*_(1824_/_768))];
  }

  .order-lookup-keyboard-items {
    @apply gap-[calc(1.25rem_*_(2.19))] max-w-[calc(325px_*_2.19)];
  }

  .order-lookup-keyboard-items button {
    @apply min-h-[calc(70px_*_2.19)] min-w-[calc(95px_*_2.19)];
  }
}
