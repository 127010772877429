.order-method-wrap {
  @apply grid grid-cols-4 gap-x-[2px] h-[45px] mt-[2px];
}

.ord-text-style {
  @apply flex justify-center items-center text-white cursor-pointer text-base-700-normal select-none;
}

.order-type {
  @apply bg-midnight-400;
}

.order-type.active {
  @apply bg-jade-500;
}

.ord-delivery {
  @apply bg-jade-500;
  box-shadow: 0px 2px 2px 0px rgba(16, 12, 47, 0.4);
}

.ord-pickup {
  @apply bg-midnight-400 opacity-80;
}

.ord-dine-in {
  @apply bg-midnight-400;
}

.ord-arrow-down {
  @apply bg-midnight-400 opacity-60;
}

.ord-dropdown-active {
  @apply bg-jade-500 opacity-100;
}

.ord-arrow-show {
  @apply rotate-180;
}

.ord-options {
  @apply flex flex-col gap-[2px] mt-[2px];
}

.ord-option {
  @apply flex items-center bg-midnight-400 h-[45px];
}

.ord-option-checkbox-wrap {
  @apply h-full px-6 cursor-pointer flex items-center justify-center;
}

.ord-option-checkbox {
  @apply w-4 h-4 bg-common-150 border border-common-140;
}

.ord-option-checked {
  @apply bg-jade-500 border-jade-500;
}

.ord-option-label {
  @apply text-base-700-normal py-3.5 pr-4 flex-1;
}

// Responsive
@media screen and (min-width: 2048px) {
  .order-method-wrap {
    @apply h-[calc(45px_*_(1536_/_768))] gap-x-[calc(2px_*_(2048_/_1366))] mt-[calc(2px_*_(1536_/_768))];
  }

  .ord-text-style {
    @apply text-[calc(16px_*_(1.75))];
  }

  .icon-arrow-down {
    @apply w-[calc(13px_*_(2048_/_1366))];
  }

  .ord-arrow-down img {
    @apply w-[calc(13px_*_(2048_/_1366))] h-[8px_*_(1536_/_768)];
  }

  .ord-option-checkbox-wrap {
    @apply px-[calc(24px_*_(2048_/_1366))];
  }

  .ord-options {
    @apply gap-[calc(2px_*_(1.75))] mt-[calc(2px_*_(1536_/_768))];
  }

  .ord-option {
    @apply h-[calc(45px_*_(1536_/_768))];
  }

  .ord-option-checkbox {
    @apply w-[calc(16px_*_(1.75))] h-[calc(16px_*_(1.75))];
  }

  .ord-option-label {
    @apply text-[calc(16px_*_(1.75))];
  }
}

@media screen and (min-width: 2736px) {
  .order-method-wrap {
    @apply h-[calc(45px_*_(1824_/_768))] gap-x-[calc(2px_*_(2736_/_1366))] mt-[calc(2px_*_(1824_/_768))];
  }

  .ord-text-style {
    @apply text-[calc(16px_*_(2.19))];
  }

  .icon-arrow-down {
    @apply w-[calc(13px_*_(2736_/_1366))];
  }

  .ord-arrow-down img {
    @apply w-[calc(13px_*_(2736_/_1366))] h-[8px_*_(1824_/_768)];
  }

  .ord-option-checkbox-wrap {
    @apply px-[calc(24px_*_(2736_/_1366))];
  }

  .ord-options {
    @apply gap-[calc(2px_*_(2.19))] mt-[calc(2px_*_(1824_/_768))];
  }

  .ord-option {
    @apply h-[calc(45px_*_(1824_/_768))];
  }

  .ord-option-checkbox {
    @apply w-[calc(16px_*_(2.19))] h-[calc(16px_*_(2.19))];
  }

  .ord-option-label {
    @apply text-[calc(16px_*_(2.19))];
  }
}
