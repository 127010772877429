.footer-container {
  @apply h-full flex justify-between gap-[10px] py-[15px] pl-6 pr-[37px];
}

.footer-group-button {
  @apply w-full h-full grid grid-cols-8 gap-[10px];
}

.footer-button-send {
  @apply w-[115px];
}

// Responsive
@media screen and (min-width: 2048px) {
  .footer-container {
    @apply gap-[calc(10px_*_1.75)] py-[calc(15px_*_(1536_/_768))] pl-[calc(24px_*_(2048_/_1366))] pr-[calc(37px_*_(2048_/_1366))];
  }

  .arrow-up {
    @apply w-[calc(12px_*_(2048_/_1366))];
  }

  .footer-group-button {
    @apply gap-[calc(10px_*_1.75)];
  }

  .footer-button-send {
    @apply w-[calc(115px_*_(2048_/_1366))];
  }
}

@media screen and (min-width: 2736px) {
  .footer-container {
    @apply gap-[calc(10px_*_2.19)] py-[calc(15px_*_(1824_/_768))] pl-[calc(24px_*_(2736_/_1366))] pr-[calc(37px_*_(2736_/_1366))];
  }

  .arrow-up {
    @apply w-[calc(12px_*_(2736_/_1366))];
  }

  .footer-group-button {
    @apply gap-[calc(10px_*_2.19)];
  }

  .footer-button-send {
    @apply w-[calc(115px_*_(2736_/_1366))];
  }
}
