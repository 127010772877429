.welcome-container {
  @apply relative w-screen h-screen min-w-[1366px] bg-gradient-to-b from-common-10 to-common-20;
}

.welcome-footer {
  @apply absolute left-0 bottom-0 w-full h-[90px] bg-gradient-to-b from-white from-30% to-common-180 to-100% pr-[47px] pl-[45px];
}

.welcome-content {
  @apply flex flex-col justify-center h-[calc(100%-90px)] px-[259px] pb-[50px] pt-[35px];
}

.welcome-content-greetings {
  @apply flex flex-col items-center gap-[31px] mb-[34px];
}

.welcome-content-function {
  @apply grid grid-cols-3 gap-2.5 rounded-[40px] overflow-hidden;
}

// Responsive
@media screen and (min-width: 2048px) {
  .welcome-footer {
    @apply h-[calc(90px_*_(1536_/_768))] 
    pr-[calc(47px_*_(2048_/_1366))]
    pl-[calc(45px_*_(2048_/_1366))];
  }

  .welcome-content {
    @apply h-[calc(100%_-_(90px_*_(1536_/_768)))]
    px-[calc(259px_*_(2048_/_1366))] 
    pb-[calc(50px_*_(1536_/_768))] 
    pt-[calc(35px_*_(1536_/_768))];
  }

  .welcome-content-greetings {
    @apply gap-[calc(31px_*_(1.75))] mb-[calc(34px_*_(1536_/_768))];
  }

  .welcome-content-function {
    @apply gap-[calc(10px_*_(1.75))] rounded-[calc(40px_*_(1.75))];
  }
}

@media screen and (min-width: 2736px) {
  .welcome-footer {
    @apply h-[calc(90px_*_(1824_/_768))] 
    pr-[calc(47px_*_(2736/_1366))]
    pl-[calc(45px_*_(2736/_1366))];
  }

  .welcome-content {
    @apply h-[calc(100%_-_(90px_*_(1824_/_768)))] 
    px-[calc(259px_*_(2736_/_1366))] 
    pb-[calc(50px_*_(1824_/_768))] 
    pt-[calc(35px_*_(1824_/_768))];
  }

  .welcome-content-greetings {
    @apply gap-[calc(31px_*_(2.19))] mb-[calc(34px_*_(1824_/_768))];
  }

  .welcome-content-function {
    @apply gap-[calc(10px_*_(2.19))] rounded-[calc(40px_*_(2.19))];
  }
}
