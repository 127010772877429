.button-base-container {
  @apply min-h-[70px] h-full w-full justify-center items-center bg-midnight-400 rounded-[10px];
}

// Number style
.base-button-type-number {
  @apply rounded-[10px] text-30-400-normal text-white min-w-[95px] w-full min-h-[70px] h-full;
}

.base-button-background-1 {
  @apply bg-midnight-500;
}

.base-button-background-2 {
  @apply bg-common-110;
}

// Text style
.base-button-text-18 {
  @apply text-xl-700-normal text-white min-w-[95px] w-full min-h-[70px] h-full rounded-[10px];
}

.base-button-text-20 {
  @apply text-20-700-normal text-white min-w-[153px] w-full min-h-[70px] h-full rounded-[10px];
}

@media screen and (min-width: 2048px) {
  .base-button-type-number {
    @apply rounded-[calc(10px_*_(1.75))] 
    text-[calc(30px_*_(1.75))] 
    min-w-[calc(95px_*_(2048_/_1366))] 
    min-h-[calc(70px_*_(1536_/_768))];
  }

  .base-button-text-18 {
    @apply text-[calc(18px_*_(1.75))] 
    rounded-[calc(10px_*_(1.75))] 
    min-w-[calc(95px_*_(2048_/_1366))] 
    min-h-[calc(70px_*_(1536_/_768))];
  }

  .base-button-text-20 {
    @apply text-[calc(20px_*_(1.75))] 
    rounded-[calc(10px_*_(1.75))] 
    min-w-[calc(153px_*_(2048_/_1366))] 
    min-h-[calc(70px_*_(1536_/_768))];
  }
}

@media screen and (min-width: 2736px) {
  .base-button-type-number {
    @apply rounded-[calc(10px_*_(2.19))] 
    text-[calc(30px_*_(2.19))]
    min-w-[calc(95px_*_(2736_/_1366))] 
    min-h-[calc(70px_*_(1824_/_768))];
  }

  .base-button-text-18 {
    @apply text-[calc(18px_*_(2.19))] 
    rounded-[calc(10px_*_(2.19))]
    min-w-[calc(95px_*_(2736_/_1366))] 
    min-h-[calc(70px_*_(1824_/_768))];
  }

  .base-button-text-20 {
    @apply text-[calc(20px_*_(2.19))] 
    rounded-[calc(10px_*_(2.19))]
    min-w-[calc(153px_*_(2736_/_1366))] 
    min-h-[calc(70px_*_(1824_/_768))];
  }
}
