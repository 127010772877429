.time-container {
  @apply flex flex-row justify-center items-end;
}

.time-time-display {
  @apply text-141-400-normal mr-4 text-white leading-[0.78];
}

.time-time-display-less {
  @apply text-126-400-normal leading-[0.78] mr-2 text-white;
}

.time-meridiem {
  @apply text-40-700-normal pb-1 text-white;
}

.time-meridiem-less {
  @apply text-40-700-normal pb-1 text-white;
}

// Responsive
@media screen and (min-width: 2048px) {
  .time-time-display {
    @apply text-[calc(141px_*_(1.75))] mr-[calc(16px_*_(2048_/_1366))];
  }

  .time-time-display-less {
    @apply text-[calc(126px_*_(1.75))] mr-[calc(8px_*_(2048_/_1366))];
  }

  .time-meridiem {
    @apply text-[calc(40px_*_(1.75))] pb-[calc(40px_*_(1536_/_768))];
  }

  .time-meridiem-less {
    @apply text-[calc(40px_*_(1.75))] pb-[calc(4px_*_(1536_/_768))];
  }
}

@media screen and (min-width: 2736px) {
  .time-time-display {
    @apply text-[calc(141px_*_(2.19))] mr-[calc(16px_*_(2736_/_1366))];
  }

  .time-time-display-less {
    @apply text-[calc(126px_*_(2.19))] mr-[calc(8px_*_(2736_/_1366))];
  }

  .time-meridiem {
    @apply text-[calc(40px_*_(2.19))] pb-[calc(40px_*_(1824_/_768))];
  }

  .time-meridiem-less {
    @apply text-[calc(40px_*_(2.19))] pb-[calc(4px_*_(1824_/_768))];
  }
}
