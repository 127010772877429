.select-container {
  @apply bg-transparent relative min-w-[57px];
}

.select-selected-wrap,
.select-selected-wrap-active {
  @apply flex items-center justify-between gap-x-[14px] py-1.5 px-[10px] border border-midnight-500 cursor-pointer;
}

.select-selected-wrap {
  @apply rounded-xl;
}

.select-selected-wrap-active {
  @apply rounded-t-xl;
}

.select-selected {
  @apply text-midnight-500 text-base-700-normal capitalize flex-1;
}

.select-selected-arrow-rotate {
  @apply rotate-180;
}

.select-options {
  @apply list-none absolute top-full left-0 right-0 z-10;
}

.select-option {
  @apply w-full text-midnight-500 text-base-700-normal py-1.5 px-[10px] border-b border-x border-midnight-500 cursor-pointer bg-white;
}

.select-option:last-child {
  @apply rounded-b-xl;
}

// Responsive
@media screen and (min-width: 2048px) {
  .food-order-container {
    @apply py-[calc(11px_*_(1536_/_768))] pl-[calc(18px_*_(2048_/_1366))] pr-[calc(15px_*_(2048_/_1366))];
  }

  .food-order-title,
  .food-order-title-price {
    @apply text-[calc(16px_*_1.75)];
  }
  //

  .select-container {
    @apply min-w-[calc(57px_*_(2048_/_1366))];
  }

  .select-selected-wrap,
  .select-selected-wrap-active {
    @apply gap-x-[calc(14px_*_(2048_/_1366))] py-[calc(6px_*_(1536_/_768))] px-[calc(10px_*_(2048_/_1366))] border-[calc(1px_*_1.75)];
  }

  .select-selected-wrap {
    @apply rounded-[calc(12px_*_1.75)];
  }

  .select-selected-wrap-active {
    @apply rounded-t-[calc(12px_*_(1536_/_768))];
  }

  .select-selected {
    @apply text-[calc(16px_*_1.75)];
  }

  .select-option {
    @apply text-[calc(16px_*_1.75)] py-[calc(6px_*_(1536_/_768))] px-[calc(10px_*_(2048_/_1366))] border-b-[calc(1px_*_(1536_/_768))] border-x-[calc(1px_*_(2048_/_1366))];
  }

  .icon-arrow,
  .select-selected-arrow-rotate {
    @apply w-[calc(13px_*_(2048_/_1366))];
  }
}

@media screen and (min-width: 2736px) {
  .food-order-container {
    @apply py-[calc(11px_*_(1824_/_768))] pl-[calc(18px_*_(2736_/_1366))] pr-[calc(15px_*_(2736_/_1366))];
  }

  .food-order-title,
  .food-order-title-price {
    @apply text-[calc(16px_*_2.19)];
  }
  //

  .select-container {
    @apply min-w-[calc(57px_*_(2736_/_1366))];
  }

  .select-selected-wrap,
  .select-selected-wrap-active {
    @apply gap-x-[calc(14px_*_(2736_/_1366))] py-[calc(6px_*_(1824_/_768))] px-[calc(10px_*_(2736_/_1366))] border-[calc(1px_*_2.19)];
  }

  .select-selected-wrap {
    @apply rounded-[calc(12px_*_2.19)];
  }

  .select-selected-wrap-active {
    @apply rounded-t-[calc(12px_*_(1824_/_768))];
  }

  .select-selected {
    @apply text-[calc(16px_*_2.19)];
  }

  .select-option {
    @apply text-[calc(16px_*_2.19)] py-[calc(6px_*_(1824_/_768))] px-[calc(10px_*_(2736_/_1366))] border-b-[calc(1px_*_(1824_/_768))] border-x-[calc(1px_*_(2736_/_1366))];
  }

  .icon-arrow,
  .select-selected-arrow-rotate {
    @apply w-[calc(13px_*_(2736_/_1366))];
  }
}
