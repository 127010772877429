.time-clock-container {
  @apply flex flex-row justify-end;
}

.time-clock-icon {
  @apply bg-midnight-400  px-[25px] py-5 rounded-bl-3xl mr-[2px];
}

.time-clock-content {
  @apply flex flex-row bg-midnight-400 px-[30px] pt-[21px] pb-5;
}

.time-clock-content-text {
  @apply text-white text-xl-400-normal mr-[5px];
}

.time-clock-content-text-bold {
  @apply text-white text-xl-700-normal;
}

@media screen and (min-width: 2048px) {
  .time-clock-icon {
    @apply px-[calc(25px_*_(2048_/_1366))] py-[calc(1.25rem_*_(1536_/_768))] rounded-bl-[calc(1.5rem_*_(1.75))] mr-[calc(2px_*_(2048_/_1366))];
  }

  .time-clock-icon img {
    @apply w-[calc(22px_*_(2048_/_1366))] h-[calc(26px_*_(1536_/_768))];
  }

  .time-clock-content {
    @apply px-[calc(30px_*_(2048_/_1366))] pt-[calc(21px_*_(1536_/_768))] pb-[calc(1.25rem_*_(1536_/_768))];
  }

  .time-clock-content-text {
    @apply text-[calc(18px_*_(1.75))] mr-[calc(5px_*_(2048_/_1366))];
  }

  .time-clock-content-text-bold {
    @apply text-[calc(18px_*_(1.75))];
  }
}

@media screen and (min-width: 2736px) {
  .time-clock-icon {
    @apply px-[calc(25px_*_(2736_/_1366))] py-[calc(1.25rem_*_(1824_/_768))] rounded-bl-[calc(1.5rem_*_(2.19))] mr-[calc(2px_*_(2736_/_1366))];
  }

  .time-clock-icon img {
    @apply w-[calc(22px_*_(2736_/_1366))] h-[calc(26px_*_(1824_/_768))];
  }

  .time-clock-content {
    @apply px-[calc(30px_*_(2736_/_1366))] pt-[calc(21px_*_(1824_/_768))] pb-[calc(1.25rem_*_(2736_/_1366))];
  }

  .time-clock-content-text {
    @apply text-[calc(18px_*_(2.19))] mr-[calc(5px_*_(2736_/_1366))];
  }

  .time-clock-content-text-bold {
    @apply text-[calc(18px_*_(2.19))];
  }
}
