.table-order {
  @apply w-full text-sm text-left rtl:text-right;
}

.table-order-thead {
  @apply bg-common-90 border-2 border-common-100 px-5 py-[11px] text-base-700-normal text-white text-center;
}

.table-order-trow {
  @apply bg-common-110 border-common-100 border;
}

.table-order-cell {
  @apply py-[11px] text-sm-700-normal text-white text-center;
}

.table-order-cell.table-order-cell-custom {
  @apply py-[11px] text-sm-700-normal text-white text-center;
}

.table-order-cell.table-order-cell-custom .table-order-cell-custom-info-wrap {
  @apply py-[11px] text-sm-700-normal text-white text-center;
}

.table-order-custom-info-wrap .custom-info-wrap-item {
  @apply py-[11px] text-sm-700-normal text-white text-center;
}

.table-order-cell-custom-info-wrap {
  @apply flex flex-col items-start;
}

.custom-info-wrap-sub-item {
  @apply text-sm-400-normal text-white opacity-50;
}

@media screen and (min-width: 2048px) {
  .table-order {
    @apply text-[calc(15px_*_(1.75))];
  }

  .table-order-thead {
    @apply border-[calc(2px_*_(1.75))] px-[calc(1.25rem_*_(2048_/_1366))] py-[calc(11px_*_(1536_/_768))] text-[calc(16px_*_(1.75))];
  }

  .table-order-trow {
    @apply border-[calc(1px_*_(1.75))];
  }

  .table-order-cell {
    @apply py-[calc(11px_*_(1536_/_768))] text-[calc(15px_*_(1.75))];
  }

  .table-order-cell.table-order-cell-custom {
    @apply py-[calc(11px_*_(1536_/_768))] text-[calc(15px_*_(1.75))];
  }

  .table-order-cell.table-order-cell-custom .table-order-cell-custom-info-wrap {
    @apply py-[calc(11px_*_(1536_/_768))] text-[calc(15px_*_(1.75))];
  }

  .table-order-custom-info-wrap .custom-info-wrap-item {
    @apply py-[calc(11px_*_(1536_/_768))] text-[calc(15px_*_(1.75))];
  }

  .custom-info-wrap-sub-item {
    @apply text-[calc(15px_*_(1.75))];
  }
}

@media screen and (min-width: 2736px) {
  .table-order {
    @apply text-[calc(15px_*_(2.19))];
  }

  .table-order-thead {
    @apply border-[calc(2px_*_(2.19))] px-[calc(1.25rem_*_(2736_/_1366))] py-[calc(11px_*_(1824_/_768))] text-[calc(16px_*_(2.19))];
  }

  .table-order-trow {
    @apply border-[calc(1px_*_(2.19))];
  }

  .table-order-cell {
    @apply py-[calc(11px_*_(1824_/_768))] text-[calc(15px_*_(2.19))];
  }

  .table-order-cell.table-order-cell-custom {
    @apply py-[calc(11px_*_(1824_/_768))] text-[calc(15px_*_(2.19))];
  }

  .table-order-cell.table-order-cell-custom .table-order-cell-custom-info-wrap {
    @apply py-[calc(11px_*_(1824_/_768))] text-[calc(15px_*_(2.19))];
  }

  .table-order-custom-info-wrap .custom-info-wrap-item {
    @apply py-[calc(11px_*_(1824_/_768))] text-[calc(15px_*_(2.19))];
  }

  .custom-info-wrap-sub-item {
    @apply text-[calc(15px_*_(2.19))];
  }
}
