.dialog-container {
  @apply flex fixed inset-0 z-10 overflow-y-auto items-center justify-center;
}

.overlay {
  @apply fixed inset-0 bg-common-70;
}

.content {
  @apply flex fixed bg-white rounded-[12px] w-[576px] h-[155px] items-center justify-center shadow-md;
}

.description {
  @apply text-32-400-normal;
}

// Responsive
@media screen and (min-width: 2048px) {
  .content {
    @apply rounded-[calc(12px_*_(1.75))] w-[calc(576px_*_(2048_/_1366))] h-[calc(155_*_(1536/768))];
  }

  .description {
    @apply text-[calc(32px_*_(1.75))]
  }
}

@media screen and (min-width: 2736px) {
  .content {
    @apply rounded-[calc(12px_*_(2.19))] w-[calc(576px_*_(2736_/_1366))] h-[calc(155_*_(1824/768))];
  }

  .description {
    @apply text-[calc(32px_*_(2.19))]
  }
}
